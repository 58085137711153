$blue: #0697DB;
$black: #000000;
$greenDark: #1A5560;
$greenLight: #51AABC;

@function responsiveSize($sizeInPixel) {
  @return calc(#{$sizeInPixel}px / var(--desktopWidthRef) * var(--screenWidth));
}

@function responsiveSizeMobile($sizeInPixel) {
  @return calc(#{$sizeInPixel}px / var(--mobileWidthRef) * var(--screenWidth));
}

@media only screen and (min-width: 776px) {
  .mobile {
    display: none !important;
  }
}

@media only screen and (max-width: 775px) {
  .desktop {
    display: none !important;
  }
}

.anchor {
  height: 0;
  position: absolute;
  margin-top: responsiveSize(-100);
}

@media only screen and (max-width: 775px) {
  .anchor {
    margin-top: responsiveSize(-200);
  }
}