@import "../common.scss";

//Mobile
@media only screen and (max-width: 775px) {
  .homepage {
    text-align: center;
    font-family: 'Baloo Bhaijaan 2';
    font-weight: normal; //Regular
    background-color: white;
    color: black;
    margin-top: responsiveSizeMobile(-25);

    &__awarded {
      height: responsiveSizeMobile(650);
      background-color: #E9CEE5;

      &-title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-family: 'Baloo Bhaijaan 2';
        font-size: responsiveSizeMobile(38);
        padding-top: responsiveSizeMobile(6);

        .lf-player-container {
          margin-left: responsiveSizeMobile(-10);

          .awardAnimation {
            width: responsiveSizeMobile(102);
            height: responsiveSizeMobile(102);
          }
        }
      }

      &-winner {
        display: flex;
        justify-content: center;
        margin-top: responsiveSizeMobile(13);
        height: responsiveSizeMobile(320);

        &-box {
          height: fit-content;
          width: responsiveSizeMobile(220);

          &:not(:first-child) {
            margin-left: responsiveSizeMobile(20);
          }

          h1,
          h2 {
            font-family: 'Baloo Bhaijaan 2';
          }

          h1 {
            font-weight: bold;
            font-size: responsiveSizeMobile(22);
          }

          h2 {
            font-size: responsiveSizeMobile(30);
            margin-top: responsiveSizeMobile(5);
            margin-bottom: responsiveSizeMobile(3);
          }

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: responsiveSizeMobile(209);
            height: responsiveSizeMobile(34);
            font-size: responsiveSizeMobile(26);
            font-family: 'Avenir';
            margin: auto;
            background-color: black;
            color: white;
            text-transform: uppercase;
          }
        }
      }

      &-winner-special {
        &-box {
          display: flex;
          justify-content: center;

          img {
            width: responsiveSizeMobile(125);
            height: responsiveSizeMobile(125);
          }

          >div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: responsiveSizeMobile(23);

            h2 {
              font-family: 'Baloo Bhaijaan 2';
              font-size: responsiveSizeMobile(30);
            }

            a {
              display: flex;
              align-items: center;
              justify-content: center;
              width: responsiveSizeMobile(209);
              height: responsiveSizeMobile(34);
              font-size: responsiveSizeMobile(26);
              font-family: 'Avenir';
              margin: auto;
              background-color: black;
              color: white;
              text-transform: uppercase;
              margin-top: 0;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &__description {
      height: responsiveSizeMobile(1223.16);
      background-image: url("../assets/images/description_foreground.svg"), url("../assets/images/description_bg_gradient.svg");
      background-size: cover, cover;
      background-position: bottom responsiveSizeMobile(-13) left, center center;
      background-repeat: no-repeat, no-repeat;

      .logoAwladContest {
        margin-top: responsiveSizeMobile(25.5);
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: responsiveSizeMobile(240);
        position: relative;
        margin-bottom: responsiveSizeMobile(9);

        img {
          width: responsiveSizeMobile(486.25);
        }

        .contestLogoAnimated {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: auto;
          width: responsiveSizeMobile(640);
        }
      }

      .description {
        margin-left: responsiveSizeMobile(94.4);
        margin-right: responsiveSizeMobile(51.6);
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: left;

        .description1 {
          text-align: right;
          width: responsiveSizeMobile(629);
          height: responsiveSizeMobile(103);
          line-height: responsiveSizeMobile(45);
          font-size: responsiveSizeMobile(50);
          font-weight: bold;
        }

        .description1-french {
          width: 100%;
          text-align: right;
          height: responsiveSizeMobile(103);
          line-height: responsiveSizeMobile(45);
          font-size: responsiveSizeMobile(41.5);
          font-weight: bold;
        }

        .description2 {
          display: flex;
          text-align: right;
          flex-direction: column;
          align-items: flex-end;
          height: responsiveSizeMobile(130);
          width: responsiveSizeMobile(499);
          font-size: responsiveSizeMobile(36);
          line-height: responsiveSizeMobile(36);
        }


        .description2-french {
          display: flex;
          text-align: right;
          flex-direction: column;
          align-items: flex-end;
          height: responsiveSizeMobile(130);
          width: responsiveSizeMobile(499);
          font-size: responsiveSizeMobile(30);
        }

        .participate {
          display: grid;
          align-items: center;
          justify-content: center;
          width: responsiveSizeMobile(309.82);
          height: responsiveSizeMobile(70);
          padding-top: responsiveSizeMobile(6);
          border-radius: responsiveSizeMobile(4);
          font-family: 'Avenir';
          background-color: black;
          font-size: responsiveSizeMobile(39);
          line-height: responsiveSizeMobile(52);

          button {
            color: white;
            font-weight: 700;
          }
        }
      }
    }

    &__soon {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
      height: responsiveSizeMobile(770);
      background-color: #311d71;
      padding-top: responsiveSizeMobile(59);

      .description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: responsiveSizeMobile(36);
        line-height: responsiveSizeMobile(36);
        font-weight: normal; //Regular
        max-width: 80%;
      }

      .video {
        margin-top: responsiveSizeMobile(17);
        height: responsiveSizeMobile(420);
        width: responsiveSizeMobile(746);
        border-radius: responsiveSizeMobile(25);
        // background-image: url("../assets/images/video_layout.svg");
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        color: #000;
        overflow: hidden;

        .footballVideo {
          width: responsiveSizeMobile(746);
          height: responsiveSizeMobile(420);
          opacity: .08;
        }

        .tools-container {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          display: flex;
          flex-direction: column;
          justify-content: start;
          align-items: center;

          .remaining-time {
            margin-top: responsiveSizeMobile(106);
            display: flex;
            justify-content: center;
            column-gap: responsiveSizeMobile(46);
            font-size: responsiveSizeMobile(37);
            font-weight: 600; //semiBold

            .time-container {
              display: flex;
              flex-direction: column;
              justify-content: start;
              align-items: center;
              height: responsiveSizeMobile(191);

              .number {
                font-size: responsiveSizeMobile(109);
                margin-bottom: responsiveSizeMobile(-44);
              }

              .unit {}
            }
          }

          .play {
            display: none;
          }
        }

        .description {
          margin-top: responsiveSizeMobile(5);
          color: black;
          font-size: responsiveSizeMobile(39);
          line-height: responsiveSizeMobile(41);
          font-weight: bold; //semiBold
        }
      }

      .share {
        margin-top: responsiveSizeMobile(17);
        width: responsiveSizeMobile(73.23);

        img {
          width: responsiveSizeMobile(73.23);
        }

        .text {
          font-family: 'Avenir';
          font-weight: 500;
          font-size: responsiveSizeMobile(23);
          line-height: responsiveSizeMobile(32.5);
        }
      }
    }

    &__how-to {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: responsiveSizeMobile(38);
      padding-left: responsiveSizeMobile(52);
      padding-right: responsiveSizeMobile(52);
      height: responsiveSizeMobile(2233);
      background-color: #99d0d4;
      background-image: url('../assets/images/how-to-background.svg');
      background-position: top left;
      background-size: 100%;
      background-repeat: no-repeat;

      .title {
        font-size: responsiveSizeMobile(45);
        line-height: responsiveSizeMobile(49);
        font-family: 'Fredoka One';
        font-weight: normal;
        text-transform: uppercase;
      }

      .description-title {
        margin-top: responsiveSizeMobile(27);
        width: responsiveSizeMobile(655);
        height: responsiveSizeMobile(50);
        background: #F8E7CA;

        p {
          font-size: responsiveSizeMobile(37);
          line-height: responsiveSizeMobile(40);
          font-weight: 600;
        }
      }

      .description {
        display: flex;
        flex-direction: column;
        font-size: responsiveSizeMobile(38);
        line-height: responsiveSizeMobile(41);
        font-weight: 600;
        letter-spacing: responsiveSizeMobile(-0.3);

        &__1 {
          margin-top: responsiveSizeMobile(18);

          &__desktop {
            display: none;
          }

          &__mobile {
            visibility: visible;
          }
        }

        &__2 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: responsiveSizeMobile(42);

          .to-participate-desktop {
            display: none;
          }

          .to-participate-mobile {
            visibility: visible;
            display: flex;
            flex-direction: column;
            align-items: center;

          }
        }
      }

      .hashtag {
        display: grid;
        align-items: center;
        width: responsiveSizeMobile(328);
        height: responsiveSizeMobile(54);
        font-size: responsiveSizeMobile(37);
        font-weight: bold;
        color: #F8E7CA;
        background-image: url("../assets/images/hashtag-bg.svg");
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        p {
          margin-top: responsiveSizeMobile(1.5);
        }
      }

      .categories-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 0;
        margin-right: 0;
        margin-top: responsiveSizeMobile(54.2);

        .categories-title {
          white-space: nowrap;
          font-family: 'Fredoka One';
          font-weight: 500;
          font-size: responsiveSizeMobile(45);
          letter-spacing: responsiveSizeMobile(-0.5);
          line-height: responsiveSizeMobile(49);
        }

        .categories-subTitle {
          font-family: 'Baloo Bhaijaan 2';
          font-weight: normal;
          font-size: responsiveSizeMobile(34);
          line-height: responsiveSizeMobile(36);
        }

        .categories {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          grid-auto-flow: row;
          margin-top: responsiveSizeMobile(16);
          column-gap: responsiveSizeMobile(30);
          row-gap: responsiveSizeMobile(15);

          .category {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background: #ffffff70;
            border-radius: responsiveSizeMobile(25);
            width: responsiveSizeMobile(280);
            height: responsiveSizeMobile(215);
            border-color: white;
            border-width: responsiveSizeMobile(2);

            .title-container {
              display: flex;
              flex-direction: column;
              margin-top: responsiveSizeMobile(10.5);
            }

            .title {
              font-family: 'Baloo Bhaijaan 2';
              font-weight: 600;
              font-size: responsiveSizeMobile(37);
              line-height: responsiveSizeMobile(39);
            }

            .subTitle {
              font-weight: bolder;
              font-size: responsiveSizeMobile(19);
              line-height: responsiveSizeMobile(16);
            }

            .image {
              display: grid;
              justify-items: center;
              align-items: center;
            }

            .description {
              margin-bottom: responsiveSizeMobile(12);
              font-family: 'Avenir';
              font-weight: 700;
              font-size: responsiveSizeMobile(31);
              line-height: responsiveSizeMobile(27);
            }

            .description-desktop {
              display: none;
            }

            &__1 {
              img {
                width: responsiveSizeMobile(83);
              }
            }

            &__2 {
              img {
                width: responsiveSizeMobile(169);
              }
            }

            &__3 {
              img {
                width: responsiveSizeMobile(164.12);
              }
            }

            &__4 {
              .image {
                padding-right: responsiveSizeMobile(13.6);
              }

              img {
                width: responsiveSizeMobile(75.6);
              }
            }
          }
        }
      }

      position: relative;
      z-index: 0;

      .clouds {
        width: responsiveSizeMobile(2400);
        position: absolute;
        top: responsiveSizeMobile(834);
        left: 0;
        z-index: -1;
      }

      .date-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #A8d1c4;
        margin-top: responsiveSizeMobile(26);
        font-family: 'Fredoka One';
        font-weight: 400;
        font-size: responsiveSizeMobile(72);
        line-height: responsiveSizeMobile(70);

        .title {
          font-family: 'Avenir';
          font-weight: 600;
          font-size: responsiveSizeMobile(43);
          line-height: responsiveSizeMobile(46);
          margin-bottom: responsiveSizeMobile(6);
        }

        .date {
          &__desktop {
            display: none;
          }

          &__mobile {
            visibility: visible;
          }
        }
      }

      .download-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: responsiveSizeMobile(146);
        font-weight: 600;
        font-size: responsiveSizeMobile(31);
        line-height: responsiveSizeMobile(33);

        .download {
          margin-top: responsiveSizeMobile(19);
          font-family: 'Avenir';
          font-weight: 400;
          font-size: responsiveSizeMobile(43);
          line-height: responsiveSizeMobile(47);

          .download-animation {
            width: responsiveSizeMobile(150);
          }
        }
      }
    }

    &__partners {
      z-index: 20;
      margin-top: responsiveSizeMobile(-95);
      padding-bottom: responsiveSizeMobile(108.5);
      font-weight: 600;
      font-size: responsiveSizeMobile(50);
      line-height: responsiveSizeMobile(55);
      display: flex;
      flex-direction: column;
      justify-items: center;
      position: relative;

      .title {
        white-space: nowrap;
        justify-items: center;
        justify-content: space-between;
        display: flex;
        align-items: center;

        .text-partners {
          padding-left: responsiveSizeMobile(20);
          padding-right: responsiveSizeMobile(20);
        }

        .line {
          width: 100%;
          height: responsiveSizeMobile(2);
          background-color: black;
          border-radius: responsiveSizeMobile(2);
          margin: 0;
        }
      }

      .afterWealthLogo {
        margin-top: responsiveSizeMobile(91);
        width: responsiveSizeMobile(417);
      }

      .partners-container {
        &__1 {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &__2 {
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: responsiveSizeMobile(66.5);
          margin-top: responsiveSizeMobile(91);

          .bdouinLogo {
            margin-top: responsiveSizeMobile(2);
            width: responsiveSizeMobile(196);
          }

          .afterWealthLogo {
            display: none;
          }

          .blgLogo {
            width: responsiveSizeMobile(218.2);
          }
        }
      }
    }

    &__learn-more {
      display: flex;
      flex-direction: column;
      color: white;
      line-height: 1;

      .description {
        display: flex;
        flex-direction: column;
        position: relative;
        height: responsiveSizeMobile(1010);
        padding-left: responsiveSizeMobile(95);
        padding-top: responsiveSizeMobile(100);
        padding-right: responsiveSizeMobile(95);
        font-family: 'KG Blank Space Solid';
        background-image: url("../assets/images/learn-more-bg.svg");
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        .boat {
          z-index: 10;
          position: absolute;
          bottom: responsiveSizeMobile(-74);
          left: responsiveSizeMobile(-46);
          width: responsiveSizeMobile(666);
        }

        .boatForeground {
          //z-20 bottom-0 left-0 absolute m-auto
          margin-bottom: -1px !important;
          z-index: 20;
          position: absolute;
          bottom: 0;
          left: 0;
          width: responsiveSizeMobile(1285);
        }
      }

      .text-container {
        div {
          flex: 1;
          display: flex;
          align-items: center;
          text-anchor: middle;
          justify-content: center;
          line-height: 1;
        }

        .want-to {
          font-weight: 700;
          font-family: 'Baloo Bhaijaan 2';
          font-size: responsiveSizeMobile(82.13);
        }

        .learn {
          font-size: responsiveSizeMobile(184);
          height: responsiveSizeMobile(240);
          margin-bottom: responsiveSizeMobile(-32);
        }

        .learn-french {
          font-size: responsiveSizeMobile(118);
          height: responsiveSizeMobile(187);
          margin-bottom: responsiveSizeMobile(-25);
          margin-top: responsiveSizeMobile(-25);
        }

        .practice {
          font-family: 'Baloo Bhaijaan 2';
          font-weight: 700;
          font-size: responsiveSizeMobile(63);
          margin-bottom: responsiveSizeMobile(12);
        }

        .arabic {
          font-weight: 400;
          font-size: responsiveSizeMobile(110);
        }
      }

      .links {
        background-color: #51aabc;
        display: flex;
        flex-direction: column;
        font-family: 'Baloo Bhaijaan 2';
        height: responsiveSizeMobile(1321);
        font-weight: 400;
        font-size: responsiveSizeMobile(51);
        line-height: responsiveSizeMobile(55);

        .title {
          font-size: responsiveSizeMobile(48);
          font-weight: 700;
        }

        .link-btn {
          display: grid;
          align-items: center;
          width: responsiveSizeMobile(480);
          height: responsiveSizeMobile(100);
          margin-top: responsiveSizeMobile(10);
          padding-top: responsiveSizeMobile(7);
          border-color: white;
          border-width: responsiveSizeMobile(4);
          border-radius: responsiveSizeMobile(25);
          font-weight: 700;
          font-family: 'Avenir';
          font-size: responsiveSizeMobile(49);
          line-height: responsiveSizeMobile(67);

          &:disabled {
            opacity: 0.4;
          }
        }

        .web-link {
          margin-top: responsiveSizeMobile(78);

          .desktopDownload {
            width: responsiveSizeMobile(330);
            height: responsiveSizeMobile(330);
            transform: translateY(responsiveSizeMobile(40)) translateX(responsiveSizeMobile(24));
            z-index: 10;
          }
        }

        .mobile-link {
          margin-top: responsiveSizeMobile(45);
          overflow: visible;

          .iphone {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: start;
            transform: translateX(responsiveSizeMobile(-48));
          }

          .mobileDownload {
            width: responsiveSizeMobile(292);
            height: responsiveSizeMobile(292);
            transform: translateY(responsiveSizeMobile(24));
            z-index: 10;
          }
        }

        .free {
          display: grid;
          align-items: center;
          color: black;
          width: responsiveSizeMobile(287);
          height: responsiveSizeMobile(65);
          padding-top: responsiveSizeMobile(4.6);
          margin-top: responsiveSizeMobile(24);
          border-radius: responsiveSizeMobile(4);
          background-color: #eac676;
          font-weight: 900;
          font-family: 'Avenir';
          font-size: responsiveSizeMobile(31);
          line-height: responsiveSizeMobile(42);
        }
      }

      .classroom {
        height: responsiveSizeMobile(1049);
        padding-top: responsiveSizeMobile(116);
        color: black;
        background-image: url('../assets/images/classroom-bg.svg');
        background-position: left bottom;
        background-repeat: no-repeat;
        background-size: contain;
        background-color: white;

        .classroom-description {
          font-size: responsiveSizeMobile(40);
          font-weight: 600;
          line-height: responsiveSizeMobile(44);
        }

        .classroom-logo {
          margin-top: responsiveSizeMobile(45);
          width: responsiveSizeMobile(419);
        }

        .link {
          margin-top: responsiveSizeMobile(26);
          font-family: 'Avenir';
          text-decoration: underline black;
          font-size: responsiveSizeMobile(31);
          font-weight: 900;
          line-height: responsiveSizeMobile(42);
        }
      }
    }
  }

  .homepage-page_main.language_ar {
    .homepage__description .description .description1 {
      margin-top: responsiveSizeMobile(10) !important;
      line-height: responsiveSizeMobile(60) !important;
    }
    .homepage__description .description .description2 {
      margin-top: responsiveSizeMobile(20) !important;
    }
  }

  .homepage-page_main.language_id { 
    .homepage__learn-more .text-container .learn {
      height: responsiveSizeMobile(140);
      font-size: responsiveSizeMobile(140);
      margin-top: responsiveSizeMobile(10);
      margin-bottom: responsiveSizeMobile(30);
    }
    .homepage__description .description .participate {
      margin-top: responsiveSizeMobile(40);
    }
  }
}