@import "../font.scss";
@import "../common.scss";

body {
  margin: auto;
  background-color: black;
  height: 100vh;
  overflow-x: hidden;
}

#main {
  margin: auto;
  width: responsiveSize(var(--desktopWidthRef));
  font-family: 'Avenir';
  color: white;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 0 !important;
  background-color: black;
  position:relative;
}
