@import "../common.scss";

@media only screen and (max-width: 775px) { //MOBILE

  footer {
    width: responsiveSizeMobile(var(--desktopWidthRef));
    height: responsiveSizeMobile(1590);
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .main-container {
      flex: 1;
      width: 100%;
      padding: responsiveSizeMobile(129.4) responsiveSizeMobile(49) responsiveSizeMobile(111.5) responsiveSizeMobile(49);
      height: responsiveSizeMobile(1358);
      display: flex;
      flex-direction: column;
      row-gap: responsiveSizeMobile(71.3);
    }

    .col1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: responsiveSizeMobile(36.8);

      .logos {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        svg {
          path {
            fill: white
          }
        }

        .schoolLogo {
          width: responsiveSizeMobile(205.38);
          height: responsiveSizeMobile(92.4);
        }

        .classroomLogo {
          width: responsiveSizeMobile(203.92);
          height: responsiveSizeMobile(82.83);
        }

        .contestLogo {
          width: responsiveSizeMobile(205.26);
          height: responsiveSizeMobile(80.22);
        }
      }

      p {
        height: responsiveSizeMobile(445);
        line-height: responsiveSizeMobile(40);
        font-size: responsiveSizeMobile(35);
        font-family: 'Baloo Bhaijaan 2';
      }
    }

    .col2 {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: 'Baloo Bhaijaan 2';
      font-size: responsiveSizeMobile(45);
      line-height: responsiveSizeMobile(40);
      font-weight: 600;
      row-gap: responsiveSizeMobile(28.3);

      div {
        height: responsiveSizeMobile(77);
        user-select: none;
        cursor: pointer;
      }

      .title {
        color: #808080;
        font-weight: 400;
        cursor: revert;
      }
    }

    .copyright {
      width: 100%;
      height: responsiveSizeMobile(232);
      border-top: 2px solid #EDEDED;
      font-family: 'Avenir';
      font-weight: 900;
      font-size: responsiveSizeMobile(27);
      display: flex;
      justify-content: center;
      align-items: start;
      padding-top: responsiveSizeMobile(15);
    }
  }
}

@media (min-width: 776px) { //Desktop

  footer {
    width: responsiveSize(var(--desktopWidthRef));
    height: responsiveSize(916);
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .main-container {
      flex: 1;
      width: 100%;
      padding: 0 responsiveSize(300) 0 responsiveSize(393);
      display: flex;
      column-gap: responsiveSize(299);
    }

    .col1 {
      width: responsiveSize(782);
      margin-top: responsiveSize(168);
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: responsiveSize(36.8);

      .logos {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        svg {
          path {
            fill: white
          }
        }

        .schoolLogo {
          width: responsiveSize(228.54);
          height: responsiveSize(102.82);
        }

        .classroomLogo {
          width: responsiveSize(226.91);
          height: responsiveSize(92.17);
        }

        .contestLogo {
          width: responsiveSize(228.4);
          height: responsiveSize(89.27);
        }
      }

      p {
        line-height: responsiveSize(40);
        font-size: responsiveSize(30);
        font-family: 'Baloo Bhaijaan 2';
      }
    }

    .col2 {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: responsiveSize(223.6);
      font-family: 'Baloo Bhaijaan 2';
      font-size: responsiveSize(35);
      line-height: responsiveSize(40);
      font-weight: 600;

      div {
        height: responsiveSize(60);
        user-select: none;
        cursor: pointer;
      }

      .title {
        color: #808080;
        margin-bottom: responsiveSize(30);
        font-weight: 400;
        cursor: revert;
      }
    }

    .copyright {
      width: 100%;
      height: responsiveSize(76);
      border-top: 2px solid #EDEDED;
      font-family: 'Avenir';
      font-weight: 900;
      font-size: responsiveSize(27);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}