@font-face {
  font-family: 'Ayuthaya';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Ayuthaya.ttf');
}

@font-face {
  font-family: 'Fredoka One';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/FredokaOne-Regular.ttf');
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 200;
  src: url('./assets/fonts/Avenir/Avenir-Light-07.ttf');
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/Avenir/Avenir-Book-01.ttf');
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Avenir/Avenir-Medium-09.ttf');
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/Avenir/Avenir-Heavy-05.ttf');
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 900;
  src: url('./assets/fonts/Avenir/Avenir-Black-03.ttf');
}


@font-face {
  font-family: 'Baloo Bhaijaan 2';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/BalooBhaijaan2-Regular.ttf');
}

@font-face {
  font-family: 'Baloo Bhaijaan 2';
  font-style: normal;
  font-weight: bold;
  src: url('./assets/fonts/BalooBhaijaan2-Bold.ttf');
}

@font-face {
  font-family: 'Baloo Bhaijaan 2';
  font-style: normal;
  font-weight: 800;
  src: url('./assets/fonts/BalooBhaijaan2-ExtraBold.ttf');
}

@font-face {
  font-family: 'Baloo Bhaijaan 2';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/BalooBhaijaan2-SemiBold.ttf');
}

@font-face {
  font-family: 'KG Blank Space Solid';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/KGBlankSpaceSolid.ttf');
}