@import "../common.scss"; //Desktop

@media (min-width: 776px) {
  .homepage {
    font-family: 'Baloo Bhaijaan 2';
    font-weight: normal; //Regular
    background-color: white;
    color: black;
    text-align: center;

    &__description {
      height: responsiveSize(1223.16);
      background-image: url("../assets/images/description_foreground_desktop.svg"), url("../assets/images/description_bg_gradient_desktop.svg");
      background-size: contain, cover;
      background-position: bottom responsiveSize(-13) left, center center;
      background-repeat: no-repeat, no-repeat;
      padding-right: responsiveSize(331);
      padding-top: responsiveSize(91);
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .logoAwladContest {
        width: responsiveSize(591.7);
        height: responsiveSize(248.5);
        position: relative;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-bottom: responsiveSize(3);

        img {
          width: responsiveSize(486.25);
        }

        .contestLogoAnimated {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: auto;
          width: responsiveSize(640);
        }
      }

      .description {
        margin-left: responsiveSize(94.4);
        margin-right: responsiveSize(22);
        display: flex;
        flex-direction: column;
        align-items: start;
        width: responsiveSize(475);

        .description1 {
          font-size: responsiveSize(50);
          line-height: responsiveSize(45);
          font-weight: bold;
          width: responsiveSize(545);
        }

        .description1-french {
          font-size: responsiveSize(50);
          line-height: responsiveSize(45);
          font-weight: bold;
          width: responsiveSize(545);
        }

        .description2 {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          font-size: responsiveSize(28);
          line-height: responsiveSize(30);
        }

        .description2-french {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          font-size: responsiveSize(28);
          line-height: responsiveSize(30);
        }

        .participate {
          margin-top: responsiveSize(15);
          display: grid;
          align-items: center;
          justify-content: center;
          width: responsiveSize(239);
          height: responsiveSize(54);
          padding-top: responsiveSize(4);
          border-radius: responsiveSize(4);
          font-weight: 700;
          font-family: 'Avenir';
          background-color: black;
          font-size: responsiveSize(31);
          line-height: responsiveSize(42);

          button {
            color: white;
          }
        }
      }
    }

    &__awarded {
      height: responsiveSize(988);
      background-color: #E9CEE5;

      &-title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-family: 'Baloo Bhaijaan 2';
        font-size: responsiveSize(68);
        padding-top: responsiveSize(6);

        .lf-player-container {
          margin-left: responsiveSize(-10);

          .awardAnimation {
            width: responsiveSize(132);
            height: responsiveSize(132);
          }
        }
      }

      &-winner {
        display: flex;
        justify-content: center;
        margin-top: responsiveSize(13);
        height: responsiveSize(500);

        &-box {
          width: responsiveSize(377);
          height: responsiveSize(281);

          &:not(:first-child) {
            margin-left: responsiveSize(50);
          }

          h1,
          h2 {
            font-family: 'Baloo Bhaijaan 2';
          }

          h1 {
            font-weight: bold;
            font-size: responsiveSize(39);
          }

          h2 {
            font-size: responsiveSize(43);
            margin-top: responsiveSize(5);
            margin-bottom: responsiveSize(3);
          }

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: responsiveSize(239);
            height: responsiveSize(54);
            font-size: responsiveSize(31);
            font-family: 'Avenir';
            margin: auto;
            background-color: black;
            color: white;
            text-transform: uppercase;
          }
        }
      }

      &-winner-special {
        h1, &-box {
          margin-left: responsiveSize(21);
        }
        &-box {
          display: flex;
          justify-content: center;

          img {
            width: responsiveSize(175);
            height: responsiveSize(175);
          }

          >div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: responsiveSize(23);

            h2 {
              font-family: 'Baloo Bhaijaan 2';
              font-size: responsiveSize(43);
            }

            a {
              display: flex;
              align-items: center;
              justify-content: center;
              width: responsiveSize(239);
              height: responsiveSize(54);
              font-size: responsiveSize(31);
              font-family: 'Avenir';
              margin: auto;
              background-color: black;
              color: white;
              text-transform: uppercase;
              margin-top: 0;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &__soon {
      color: white;
      height: responsiveSize(1050);
      background-color: #311d71;
      padding-top: responsiveSize(98);
      display: flex;
      flex-direction: column;
      align-items: center;

      .description {
        display: flex;
        flex-direction: row;
        column-gap: responsiveSize(8);
        justify-content: center;
        align-items: center;
        font-size: responsiveSize(35);
        line-height: responsiveSize(45);
        font-weight: 600; //MEDIUM
      }

      .video {
        margin-top: responsiveSize(27);
        height: responsiveSize(743);
        width: responsiveSize(1320);
        border-radius: responsiveSize(40);
        // background-image: url("../assets/images/video_layout.svg");
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        color: black;
        overflow: hidden;

        .footballVideo {
          width: 100%;
          height: 100%;
          opacity: .08; // == 8%
        }

        .tools-container {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          display: flex;
          flex-direction: column;
          justify-content: start;
          align-items: center;

          .remaining-time {
            margin-top: responsiveSize(83);
            display: flex;
            justify-content: center;
            column-gap: responsiveSize(54);
            font-size: responsiveSize(45);
            font-weight: 600; //semiBold

            .time-container {
              display: flex;
              flex-direction: column;
              justify-content: start;
              align-items: center;
              height: responsiveSize(227);

              .number {
                font-size: responsiveSize(130);
                margin-bottom: responsiveSize(-50);
              }

              .unit {}
            }
          }

          .play {
            width: responsiveSize(133);
            margin-top: responsiveSize(29);
            opacity: 0.2;
          }
        }

      }

      .share {
        margin-top: responsiveSize(24);
        width: responsiveSize(87.19);
        padding-bottom: responsiveSize(41);

        img {
          width: responsiveSize(87.19);
        }

        .text {
          font-family: 'Avenir';
          font-weight: 900;
          font-size: responsiveSize(28);
          line-height: responsiveSize(30);
        }
      }
    }

    &__how-to {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: responsiveSize(80);
      height: responsiveSize(2224);
      background-color: #99d0d4;
      background-image: url('../assets/images/how-to-background-desktop.svg');
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;

      .title {
        font-size: responsiveSize(72);
        line-height: responsiveSize(77);
        font-weight: bold;
        text-transform: uppercase;
      }

      .description-title {
        margin-top: responsiveSize(27);
        font-style: responsiveSize(43);
        line-height: responsiveSize(46);
        padding: 0 responsiveSize(7);
        height: responsiveSize(50);
        background: #F8E7CA;

        p {
          padding-top: responsiveSize(9);
          font-size: responsiveSize(37);
          line-height: responsiveSize(40);
          font-weight: 600;
        }
      }

      .description {
        display: flex;
        flex-direction: column;
        font-size: responsiveSize(42);
        line-height: responsiveSize(46);
        font-weight: 500;
        letter-spacing: responsiveSize(-0.3);

        &__1 {
          margin-top: responsiveSize(5);

          &__desktop {
            visibility: visible;
          }

          &__mobile {
            display: none;
          }
        }

        &__2 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: responsiveSize(30);

          .to-participate-desktop {
            visibility: visible;
          }

          .to-participate-mobile {
            display: none;
          }
        }
      }

      .hashtag-inline {
        display: inline-grid;
        align-items: center;
        margin-left: responsiveSize(8);
        width: responsiveSize(328);
        height: responsiveSize(54);
        font-size: responsiveSize(37);
        font-weight: bold;
        color: #F8E7CA;
        background-image: url("../assets/images/hashtag-bg.svg");
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        p {
          margin-top: responsiveSize(1.5);
        }
      }

      .categories-separator {
        width: responsiveSize(314);
        height: responsiveSize(4);
        background-color: black;
        margin-top: responsiveSize(25);
      }

      .categories-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: responsiveSize(90);
        margin-right: responsiveSize(90);
        margin-top: responsiveSize(25);

        .categories-title {
          //font-family: 'Fredoka One';
          font-weight: normal;
          font-size: responsiveSize(72);
          letter-spacing: responsiveSize(-0.5);
          line-height: responsiveSize(77);
          font-weight: bold;
        }

        .categories-subTitle {
          font-family: 'Baloo Bhaijaan 2';
          font-weight: normal;
          font-size: responsiveSize(34);
          line-height: responsiveSize(36);
        }

        .categories {
          display: grid;
          grid-template-rows: repeat(1, minmax(0, 4fr));
          grid-auto-flow: column;
          margin-top: responsiveSize(16);
          column-gap: responsiveSize(30);

          .category {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background: #ffffff70;
            border-radius: responsiveSize(25);
            width: responsiveSize(275);
            height: responsiveSize(180);
            border-color: white;
            border-width: responsiveSize(2);

            .title-container {
              display: flex;
              flex-direction: column;
              margin-top: responsiveSize(10.5);
            }

            .title {
              font-weight: 800;
              font-size: responsiveSize(35);
              line-height: responsiveSize(37);
            }

            .subTitle {
              font-weight: 800;
              font-size: responsiveSize(19);
              line-height: responsiveSize(16);
            }

            .image {
              display: grid;
              justify-items: center;
              align-items: center;
            }

            .description {
              display: none;
            }

            .description-desktop {
              margin-bottom: responsiveSize(12);
              font-family: 'Avenir';
              font-weight: normal;
              font-size: responsiveSize(24);
              line-height: responsiveSize(21);
            }

            &__1 {
              img {
                width: responsiveSize(71.38);
              }
            }

            &__2 {
              img {
                width: responsiveSize(150);
              }
            }

            &__3 {
              img {
                width: responsiveSize(141.38);
              }
            }

            &__4 {
              .image {
                padding-right: responsiveSize(13.6);
              }

              img {
                width: responsiveSize(58.57);
              }
            }
          }
        }
      }

      z-index: 0;

      .clouds {
        z-index: -1;
        width: 125%;
        position: absolute;
        top: responsiveSize(688);
        left: 0;
      }

      .date-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #A8d1c4;
        margin-top: responsiveSize(153);

        font-weight: 500;
        font-size: responsiveSize(72);
        line-height: responsiveSize(77);
        z-index: 10;

        .title {
          font-family: 'Avenir';
          font-weight: 600;
          font-size: responsiveSize(43);
          line-height: responsiveSize(46);
          margin-bottom: responsiveSize(6);
        }

        .date {
          &__desktop {
            visibility: visible;
          }

          &__mobile {
            display: none;
          }
        }
      }

      .download-container-desktop {
        margin-top: responsiveSize(-16);
        height: responsiveSize(1144);
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: responsiveSize(124);
        font-family: 'Minion Pro';
        font-weight: 700;
        font-size: responsiveSize(29);
        line-height: responsiveSize(31);
        background-color: #dce4d0;

        .slides {
          margin-top: responsiveSize(10);
          width: responsiveSize(1320);
          height: responsiveSize(743);

          img {
            width: 100%;
          }
        }

        .panel {
          display: flex;
          width: responsiveSize(1320);
          height: responsiveSize(154);
          margin-top: responsiveSize(1);
          flex-direction: row;
          justify-content: center;
          column-gap: responsiveSizeMobile(8);
          align-items: end;

          .share {
            //margin-top: responsiveSize(30.7);
            cursor: pointer;
            width: responsiveSize(73.23);
            display: grid;
            justify-items: center;

            font-family: 'Avenir';
            font-weight: 500;
            letter-spacing: responsiveSize(-0.3);
            font-size: responsiveSize(24);
            line-height: responsiveSize(26);

            img {
              width: responsiveSize(73.23);
            }

            .text {}
          }

        }

        .download {
          //margin-top: responsiveSize(25.7);
          font-family: 'Avenir';
          font-weight: 400;
          font-size: responsiveSize(24);
          line-height: responsiveSize(26);
          width: responsiveSize(86);

          .download-animation {
            width: 100%;
          }

          .text {
            margin-top: responsiveSize(-3);
            z-index: 10;
            font-weight: 900;
            font-size: responsiveSize(28);
          }
        }
      }
    }

    &__partners {
      padding-top: responsiveSize(91);
      padding-bottom: responsiveSize(195.3);
      font-weight: 700;
      font-size: responsiveSize(50);
      line-height: responsiveSize(55);
      display: flex;
      flex-direction: column;
      justify-items: center;

      .title {
        white-space: nowrap;
        justify-items: center;
        justify-content: space-between;

        .line {
          display: none;
        }
      }


      .partners-container {
        &__1 {
          display: none;
        }

        &__2 {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row-reverse;
          column-gap: responsiveSize(127);

          .bdouinLogo {
            margin-top: responsiveSize(30.5);
            width: responsiveSize(200);
          }

          .afterWealthLogo {
            margin-top: responsiveSize(79.3);
            width: responsiveSize(538.2);
          }

          .blgLogo {
            margin-top: responsiveSize(23);
            width: responsiveSize(207.6);
          }
        }
      }
    }

    &__learn-more {
      display: flex;
      flex-direction: column;
      color: white;
      line-height: 1;

      .description {
        display: flex;
        flex-direction: column;
        position: relative;
        height: responsiveSize(572);
        padding-left: responsiveSize(95);
        padding-top: responsiveSize(100);
        padding-right: responsiveSize(95);
        background-image: url("../assets/images/learn-more-bg.svg");
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        .text-container {
          position: absolute;
          top: responsiveSize(119);
          right: responsiveSize(460);

          div {

            flex: 1;
            display: flex;
            align-items: center;
            text-anchor: middle;
            justify-content: center;
            line-height: 1;
          }

          .want-to {
            font-size: responsiveSize(74);
            height: responsiveSize(127);
            margin-bottom: responsiveSize(-56);
            font-weight: 700;
          }

          .learn {
            font-family: 'KG Blank Space Solid';
            font-size: responsiveSize(106);
            height: responsiveSize(141);
            margin-bottom: responsiveSize(-26);
          }

          .learn-french {
            font-family: 'KG Blank Space Solid';
            font-size: responsiveSize(90);
            margin-top: responsiveSize(26);
          }

          .practice {
            font-weight: 700;
            font-size: responsiveSize(41);
            height: responsiveSize(70);
            margin-bottom: responsiveSize(-26);
          }

          .arabic {
            font-family: 'KG Blank Space Solid';
            font-size: responsiveSize(64);
            height: responsiveSize(85);
          }
        }

        .boat {
          z-index: 10;
          position: absolute;
          top: responsiveSize(157);
          left: responsiveSize(386);
          width: responsiveSize(693);
        }

        .boatForeground {
          z-index: 20;
          position: absolute;
          top: responsiveSize(238);
          left: 0;
          width: responsiveSize(1923);
        }
      }

      .links-desktop {
        background-color: #51aabc;
        display: flex;
        font-weight: 400;
        column-gap: responsiveSize(71);
        justify-content: center;
        font-size: responsiveSize(28);
        line-height: responsiveSize(30);
        padding-bottom: responsiveSize(121);

        .title {
          font-weight: 700;
        }

        .link-btn {
          cursor: pointer;
          display: grid;
          align-items: center;
          width: responsiveSize(290);
          height: responsiveSize(54);
          margin-top: responsiveSize(10);
          padding-top: responsiveSize(7);
          background-color: black;
          border-radius: responsiveSize(4);
          font-weight: 500;
          font-family: 'Avenir';
          font-size: responsiveSize(31);
          line-height: responsiveSize(42);

          &:disabled {
            opacity: 0.4;
          }
        }

        .web-link {
          margin-top: responsiveSize(66);

          .preview-container {
            img {
              width: responsiveSize(748.2);
            }

          }

          .desktopDownload {
            width: responsiveSize(330);
            height: responsiveSize(330);
            transform: translateY(responsiveSize(40)) translateX(responsiveSize(24));
            z-index: 10;
          }
        }

        .features {
          margin-top: responsiveSize(24);
        }

        .mobile-link {
          margin-top: responsiveSize(63);

          .preview-container {
            img {
              width: responsiveSize(219);
            }
          }

          .Download {
            width: responsiveSize(292);
            height: responsiveSize(292);
            transform: translateY(responsiveSize(24)) translateX(responsiveSize(48));
            z-index: 10;
          }
        }

        .free {
          cursor: pointer;
          display: grid;
          align-items: center;
          color: black;
          width: responsiveSize(156);
          height: responsiveSize(35);
          padding-top: responsiveSize(4.6);
          margin-top: responsiveSize(8);
          border-radius: responsiveSize(4);
          background-color: #eac676;
          font-weight: 900;
          font-family: 'Avenir';
          font-size: responsiveSize(17);
          line-height: responsiveSize(23);
        }
      }

      .classroom {
        height: responsiveSize(854);
        padding-top: responsiveSize(98);
        color: black;
        background-image: url('../assets/images/classroom_bg_desktop.svg');
        background-position: left bottom;
        background-repeat: no-repeat;
        background-size: contain;
        background-color: white;

        .classroom-description {
          font-size: responsiveSize(50);
          font-weight: 600;
          line-height: responsiveSize(55);
        }

        .classroom-logo {
          margin-top: responsiveSize(37.5);
          width: responsiveSize(507);
        }

        .link {
          margin-top: responsiveSize(26);
          font-family: 'Avenir';
          text-decoration: underline black;
          font-size: responsiveSize(31);
          font-weight: 900;
          line-height: responsiveSize(42);
        }
      }
    }
  }
}

.homepage-page_main.language_ar {

  &,
  button,
  .homepage__how-to .date-container .title,
  .homepage__how-to .date-container,
  .homepage__how-to .title,
  .homepage__how-to .download-container-desktop,
  .homepage__learn-more .description,
  .homepage__learn-more .links .link-btn,
  .homepage__learn-more .links .free,
  .homepage__learn-more .classroom .link,
  .homepage__learn-more .description .text-container .learn,
  .homepage__learn-more .description .text-container .arabic,
  .homepage__learn-more .links-desktop .link-btn,
  .homepage__learn-more .links-desktop .free,
  .homepage__how-to .categories-container .categories-title,
  .homepage__how-to .categories-container .categories .category .description,
  .homepage__how-to .categories-container .categories .category .description-desktop {
    font-family: "Baloo Bhaijaan 2";
  }

  .homepage__how-to .date-container,
  .homepage__how-to .title,
  .homepage__learn-more .description .text-container .learn,
  .homepage__how-to .categories-container .categories-title {
    font-weight: 600;
  }


  .homepage__learn-more .description .text-container .learn {
    margin-top: responsiveSize(30);
  }

  .categories-title-price {
    direction: rtl;
  }

  .homepage__description .description .description1 {
    line-height: responsiveSize(70);
  }
}